<template>
  <div class="plan">
    <div class="dataOverview">
      <span style='font-weight: 600'>数据总览</span>
      <span class="plan-Area"
        >区域 :
        <el-select v-model="regionId" @change="getregionSelect()">
          <el-option value="" label="全部区域">全部区域</el-option>
          <el-option
            v-for="(item, index) in region"
            :key="index"
            :value="item.regionId"
            :label="item.regionName"
          />
        </el-select>
      </span>
      <span class="plan-Area"
        >合同类型 :
        <el-select v-model="contractTempleteNum" @change="getContract()">
          <el-option
            v-for="(item, index) in contractList"
            :key="index"
            :label="item.name"
            :value="item.number"
          />
        </el-select>
      </span>
    </div>
    <el-card>
      <div class="contratMesO">
        <div class="m-r-20"><span>档口数量(个) :&nbsp;</span>{{ totalNum }}</div>
        <div class="m-r-20"><span>档口面积(m²) :&nbsp;</span>{{ totalFloorage|getdecimal }}</div>
        <div class="m-r-20"><span>已签订档口数量(个) :&nbsp;</span>{{ talSignNum }}({{ percentageOfsignedArea }})%</div>
      </div>
      <div class="contratMesO">
        <div class="m-r-20"><span>未签订档口数量(个) :&nbsp;</span>{{ nosignedStall }} </div>
        <div class="m-r-20"><span>已签订档口面积(m²) :&nbsp;</span>{{ totalSignFloorage|getdecimal }}({{ PercentageSignFloorage }})%</div>
        <div class="m-r-20"><span>未签订档口面积(m²) :&nbsp;</span>{{ noFloorage|getdecimal }}</div>
        <el-tooltip
          class="m-r-20"
          effect="dark"
          content="此时距物业租凭合同结束时间 ≦ 三个月"
          placement="top-start"
        >
          <span style='font-weight: 600;'>快到期档口数量(个) :</span>><span>{{ totalTemporaryNum }}</span>
        </el-tooltip>
      </div>
    </el-card>
    <div class="areaview">
      <el-card>
        <el-tabs v-model="activeName">
          <el-tab-pane label="区域视图" name="first">
            <div style='display: flex'>
              <div class='m-r-20'>显示配置:&nbsp; </div>
              <div v-for="(item, index) in list" :key="index" style='display: flex'>
                <div class="plan-green" :style="getBackground(item.businessTypeName)"> </div>
                <el-tooltip
                  v-if="item.businessTypeName === '快到期'"
                  effect="dark"
                  content="此时距物业租凭合同结束时间 ≦ 三个月"
                  placement="top-start"
                >
                  <span class="m-r-20">&nbsp;{{ item.businessTypeName }}({{ item.totalNum }})</span>
                </el-tooltip>
                <div v-else class="m-r-20">&nbsp;{{ item.businessTypeName }}({{ item.totalNum }})</div>
            </div>
            <div class="divButton">
              <div><el-button type="primary" @click="getNewarea">新增区域</el-button></div>
              <div><el-button type="primary" @click="getSortby()"> 排序 </el-button></div>
              <div><el-button type="primary" @click="exportToExcel()">导出数据</el-button></div>
            </div>
          </div>
            <div class="plancard">
              <div class="plancardareaCard" v-for="(item, index) in regionList" :key="index">
                <el-row class="row-areaText">
                  <el-col :span="16">
                    <div class="areaText">{{ item.regionName }}</div>
                  </el-col>
                  <el-col :span="7">
                    <i
                      class="el-icon-edit font-size c-989898"
                      @click="updateKeyChildren(item.regionId, item)"
                    ></i>
                    <i class="el-icon-delete" @click="remove(item)"></i>
                  </el-col>
                </el-row>
                <el-row class="plancard-head">
                  <el-col :span="5">
                    <div v-if="item.buildingCode">{{ item.buildingCode }}</div>
                  </el-col>
                  <el-col :span="9">档口数量(个):{{ item.regionTotalNum }}</el-col>
                  <el-col :span="9">总档口面积(m²):{{ item.regionTotalFloorage |getdecimal }}</el-col>
                </el-row>
                <div>
                  <div class="demo-progress">
                    <el-row v-for="(type, index) in item.regionTypeList" :key="index">
                      <el-col :span="3">{{ type.businessTypeName }}</el-col>
                      <el-col :span="16">
                        <el-progress
                          :stroke-width="10"
                          :color="getStatusColro(type.businessTypeName)"
                          :show-text="false"
                          :percentage="
                            type.totalNum === 0 && item.regionTotalNum === 0
                              ? 0
                              : parseInt((type.totalNum / item.regionTotalNum) * 100)
                          "
                      /></el-col>
                      <el-col :span="5">{{ type.totalNum }}</el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="档口视图" name="second">
            <div style='display: flex'>
              <div class='m-r-20'>显示配置: </div>
              <div v-for="(item, index) in list" :key="index" style='display: flex'>
                <div class="plan-green" :style="getBackground(item.businessTypeName)"></div>
                <el-tooltip
                  v-if="item.businessTypeName === '快到期'"
                  effect="dark"
                  content="此时距物业租凭合同结束时间 ≦ 三个月"
                  placement="top-start"
                >
                  <span class='m-r-20'>&nbsp;{{ item.businessTypeName }}({{ item.totalNum }})</span>
                </el-tooltip>
                <div v-else class='m-r-20'>&nbsp;{{ item.businessTypeName }}({{ item.totalNum }})</div>
              </div>
            </div>
            <div class="plancard" v-for="(item, index) in viewArea" :key="index">
              <div class="central">
                <div class="textlattice">{{ item.regionName }}</div>
                <div
                  class="lattice"
                  v-for="(row, index) in item.gearNumberList"
                  :key="index"
                  :style="getBackground(row.businessTypeName)"
                  @click="getDetails(row)"
                >
                  <el-row>
                    <el-col :span="10" class="numberClss" style='padding-top: 0'>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="row.number"
                        placement="top-start"
                      >
                        <span v-if="row.number" class="buildingCoder">{{ row.number }}</span>
                      </el-tooltip>
                    </el-col>
                    <el-col
                      :span="13"
                      :style="row.number ? 'text-align: right' : 'text-align:left'"
                    >
                      <span v-if="row.floorage">{{ row.floorage }}m²</span>
                    </el-col>
                  </el-row>
                  <div class="merchantName">
                    <span style="position: absolute">{{ row.merchantName }}</span>
                  </div>
                  <el-row>
                    <el-col :span="10" style='padding-top: 0'>
<!--                      <span v-if="row.contractName" >{{ row.contractName }}</span>-->
                      <span>99999</span>
                    </el-col>
                    <el-col
                      :span="13"
                      :style="row.contractName ? 'text-align: left;padding-top:0' : 'text-align: right;padding-top:0'"
                    >
                      <el-popover placement="bottom-start" title="操作" width="200" trigger="hover">
                        <div>
                          <el-row>
                            <el-col :span="12">
                              <el-button type="text" @click="updateChildren(row)"
                                >修改档口</el-button
                              >
                            </el-col>
                            <el-col :span="12">
                              <el-button
                                type="text"
                                v-if="row.settleStatus === 'Y' && row.gearBindStatus !== 'Y'"
                                @click="getBindingaccount(row)"
                                >绑定账号</el-button
                              >
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <el-button
                                type="text"
                                v-if="row.settleStatus !== 'Y'"
                                @click="getMerchantsettled(row, item)"
                                >商户入驻</el-button
                              >
                            </el-col>
                            <el-col :span="12">
                              <el-button
                                type="text"
                                v-if="row.settleStatus === 'Y'"
                                @click="saveMerchantRetreatRear(row)"
                                >退档
                              </el-button>
                            </el-col>
                            <el-col :span="12">
                              <el-button type="text" @click="removeChide(row)">删除</el-button>
                            </el-col>
                          </el-row>
                        </div>
                        <el-button
                          type="text"
                          slot="reference"
                          style="color: #ffffff"
                          >变更</el-button
                        >
                      </el-popover>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="div2">
                <div><el-button type="primary" @click="append()">新增档口</el-button></div>
                <div><el-button type="primary" @click="getSortby()">排序</el-button></div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <!--新增--->
      <el-dialog
        v-if="dialogVisible"
        ref="dialogVisible"
        :title="title"
        :visible.sync="dialogVisible"
        @close="close"
        width="550px"
        class="dialogr"
      >
        <el-form ref="form" :model="form" :rules="rules" :label-width="labelWidth">
          <el-form-item v-if="!isEdit" label="层级目录：">
            <el-select disabled v-model="creatRegionOption" placeholder="请选择">
              <el-option
                v-for="item in options"
                :disabled="optionFlag && item.id === 'peer'"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 区域-->
          <el-form-item v-if="isPeer" label="楼栋编号：" prop="buildingCode">
            <el-input
              v-model="form.buildingCode"
              placeholder="选填，请填写楼栋编号"
              :disabled="isdisabledFn"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="isPeer" prop="regionName" label="区域名称：">
            <el-input
              v-model="form.regionName"
              placeholder="必填，请填写区域名称"
              :disabled="isdisabledFn"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="isPeer" label="建筑面积：" prop="floorage">
            <el-input
              v-model="form.floorage"
              placeholder="选填，请填写建筑面积"
              :disabled="isdisabledFn"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="isPeer" label="总层数：" prop="floorsTotalNum">
            <el-input
              v-model="form.floorsTotalNum"
              placeholder="选填，请填写总层数"
              :disabled="isdisabledFn"
            ></el-input>
          </el-form-item>

          <!--档口-->
          <el-form-item v-if="!isPeer" label="选择区域：">
            <el-select
              v-model="form.regionId"
              placeholder="请选择"
              @change="getSeleRid"
              :disabled="srid"
            >
              <el-option
                v-for="item in region"
                :key="item.regionId"
                :label="item.regionName"
                :value="item.regionId"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="!isPeer" label="所属类型：" prop="businessTypeId">
            <el-select v-model="form.businessTypeId" placeholder="请选择" :disabled="isdisabledFn">
              <el-option
                v-for="item in businessTypeList"
                :key="item.businessTypeId"
                :label="item.businessTypeName"
                :value="item.businessTypeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="!isPeer" label="档口号：" prop="number">
            <el-input
              v-model.trim="form.number"
              placeholder="必填，请填写编号"
              :disabled="isdisabledFn"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="!isPeer" label="选择楼层：">
            <el-select v-model="form.floorsNum" placeholder="请选择">
              <el-option v-for="item in floorsTotalNum" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="!isPeer" label="选择标签：">
            <el-select v-model="form.dnpLabelList" multiple placeholder="请选择">
              <el-option
                v-for="item in labelList"
                :key="item.labelId"
                :label="item.labelName"
                :value="item.labelId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :rules="[{ required: true, message: '请填写档口排序号', trigger: 'blur' }]"
            prop="premiseIndex"
            v-if="!isPeer"
            :label="`档口排序号(最大${maxPremiseIndex || 0})：`"
          >
            <el-input v-model="form.premiseIndex" placeholder="必填，请填写档口排序号"></el-input>
          </el-form-item>

          <el-form-item
            :rules="[{ required: true, message: '请填写建筑面积', trigger: 'blur' }]"
            prop="floorage"
            v-if="!isPeer"
            label="建筑面积："
          >
            <el-input v-model="form.floorage" placeholder="必填，请填写建筑面积"></el-input>
          </el-form-item>
          <el-form-item prop="coefficient" v-if="!isPeer" label="算费系数：">
            <el-input v-model="form.coefficient" placeholder="必填，请填写算费系数"></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="form.remarks" placeholder="选填，请填写备注"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="addRegion('form')">确 定</el-button>
        </div>
      </el-dialog>
      <!--商户入驻-->
      <el-dialog
        title="商户入驻"
        :before-close="handleClose"
        :visible.sync="dialogMerchant"
        width="50%"
      >
        <div class="merchantSteps">
          <el-steps :active="active" align-center finish-status="success">
            <el-step title="选择商户"></el-step>
            <el-step title="绑定鲜道赢账号"></el-step>
          </el-steps>
        </div>
        <div class="condition">
          <el-input
            v-if="active !== 1"
            v-model="merchantName"
            placeholder="输入商户姓名搜素"
          ></el-input>
          <el-input
            v-model="phone"
            :placeholder="active === 1 ? '输入手机号/登录编号搜索' : '手机号搜索'"
            :class="active === 1 ? 'input' : ''"
          ></el-input>

          <el-button type="primary" @click="getMerchantInfoList">查询</el-button
          ><el-button @click="getReset">重置</el-button
          ><el-button type="primary" v-if="active !== 1" @click="getMerchants">新增商户</el-button>
        </div>
        <div class="merchant-table">
          <el-table
            v-if="active !== 1"
            :data="dnpMerchantInfoList"
            border
            style="width: 100%"
            ref="multipleTable"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" :selectable="selectable">
            </el-table-column>
            <el-table-column prop="merchantName" label="商户姓名"> </el-table-column>
            <el-table-column prop="phone" label="联系方式"> </el-table-column>
            <el-table-column prop="sex" label="性别">
              <template slot-scope="scope">
                {{ scope.row.sex | sex }}
              </template>
            </el-table-column>
            <el-table-column prop="idNumber" label="身份证号"> </el-table-column>
            <el-table-column prop="merchantCategoryInfo" label="经营品类"> </el-table-column>
          </el-table>
          <el-table
            v-if="active === 1"
            :data="gearList"
            border
            style="width: 100%"
            ref="multipleTable"
            @selection-change="selectionChange"
          >
            <el-table-column type="selection" width="55" :selectable="selectable">
            </el-table-column>
            <el-table-column prop="phone" label="登录手机号"> </el-table-column>
            <el-table-column prop="userName" label="系统用户"> </el-table-column>
            <el-table-column prop="gearName" label="档口名称"> </el-table-column>
          </el-table>

          <div>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page.current_page"
              :page-size="page.page_size"
              layout="total, prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <span slot="footer" class="dialog-footer" v-if="active === 0">
          <el-button @click="getNobinding">取消</el-button>
          <el-button type="primary" :disabled="nextstep" @click="getNexstep">下一步</el-button>
        </span>
        <span slot="footer" class="dialog-footer" v-else>
          <el-button @click="getNobinding">暂不绑定</el-button>
          <el-button type="primary" :disabled="binding" @click="getCompletebinding"
            >完成绑定</el-button
          >
        </span>
      </el-dialog>
      <!--添加供应商-->
      <el-dialog
        v-if="dialogFormVisible"
        :before-close="beforeClose"
        title="添加商户"
        :visible.sync="dialogFormVisible"
        width="800px"
      >
        <el-form :label-width="formLabelWidth" :model="addForm" :rules="rulesr" ref="addForm">
          <el-row>
            <el-col :span="14">
              <el-form-item label="商户姓名:" prop="merchantName">
                <el-input v-model="addForm.merchantName" placeholder="必填，请填写姓名"></el-input>
              </el-form-item>
              <el-form-item label="性别:">
                <el-select class="w-100" v-model="addForm.sex" placeholder="请选择">
                  <el-option label="男" :value="1" />
                  <el-option label="女" :value="0" />
                </el-select>
              </el-form-item>
              <el-form-item label="身份证号:" prop="idNumber">
                <el-input v-model="addForm.idNumber" placeholder="可选，请填写身份证号"></el-input>
              </el-form-item>
              <el-form-item label="联系方式" prop="phone">
                <el-input v-model="addForm.phone" placeholder="必填，请填写手机号"></el-input>
              </el-form-item>
              <el-form-item label="开户行:" prop="depositBank">
                <el-input
                  type="text"
                  v-model="addForm.depositBank"
                  placeholder="选填，请填写开户行"
                ></el-input>
              </el-form-item>
              <el-form-item label="户名:" prop="accountName">
                <el-input
                  type="text"
                  v-model="addForm.accountName"
                  placeholder="选填，请填写户名"
                ></el-input>
              </el-form-item>
              <el-form-item label="卡号:" prop="cardNumber">
                <el-input v-model="addForm.cardNumber" placeholder="选填，请填写卡号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <div class="header">
                <el-upload
                  class="avatar-uploader"
                  action=" "
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </el-col>
          </el-row>
          <el-form-item label="经营品类:">
            <el-tag
              class="m-r-20"
              closable
              :key="index"
              v-for="(tag, index) in addForm.dnpMerchantCategoryInfoList"
              v-if="tag.status !== 'N'"
              @close="handleCloseTag(index, tag)"
            >
              <template>
                {{ tag.categoryName }}
              </template>
            </el-tag>
            <el-button @click="openAddTag">添加</el-button>
          </el-form-item>

          <el-form-item label="信用评分:">
            <el-select v-model="addForm.creditLevel">
              <el-option
                v-for="item in creditLevelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="营业执照:">
            <el-upload
              :before-upload="beforeUpload"
              :on-exceed="handleExceed"
              :file-list="fileList"
              action="#"
              :limit="3"
              list-type="picture-card"
              :auto-upload="true"
            >
              <div slot="default">
                <div>点击上传</div>
              </div>
              <div slot="file" slot-scope="{ file }">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
              <div slot="tip">
                <span style="color: #999"
                  >最多上传三张照片。图片支持jpg、JPEG ，bmp，png。图片大小不超过5M</span
                >
              </div>
            </el-upload>
            <el-dialog :modal="false" :visible.sync="dialogVisibler">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="addForm.remark" placeholder="选填，请填备注"></el-input>
          </el-form-item>
        </el-form>
        <el-dialog append-to-body :visible.sync="dialogCategoryVisible" width="800px">
          <div slot="title">
            添加经营品种<span class="c-6 f-s-14">&nbsp;&nbsp;&nbsp; 最多选择5种</span>
          </div>
          <el-row class="m-b-20">
            <el-col class="m-b-20">
              已选:&nbsp;&nbsp;&nbsp;
              <el-tag
                class="m-r-20"
                closable
                :key="index"
                v-for="(tag, index) in dnpMerchantCategoryInfoList"
                @close="handleCloser(index)"
              >
                {{ tag.categoryName }}
              </el-tag>
            </el-col>
            <el-col :span="18">
              <el-input clearable placeholder="输入关键字进行过滤" v-model="filterText"> </el-input>
            </el-col>
          </el-row>

          <el-tree
            :data="treeData"
            node-key="categoryId"
            :props="defaultProps"
            :highlight-current="true"
            :filter-node-method="filterNode"
            show-checkbox
            check-strictly
            :check-on-click-node="true"
            @check-change="handlePriceClick"
            ref="treePriceParams"
          ></el-tree>
          <div slot="footer" class="text-center">
            <el-button @click="dialogCategoryVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitTag">我 选 好 了</el-button>
          </div>
        </el-dialog>

        <div slot="footer" class="text-center">
          <el-button @click="beforeClose()">取 消</el-button>
          <el-button type="primary" @click="submitForm('addForm')">确 定</el-button>
        </div>
      </el-dialog>
      <!--排序-->
      <DargList :dialogVisible="sortby" @dargList="dargList" />
    </div>
  </div>
</template>
<script>
import { marketplan } from '../api/api'
import { mapState } from 'vuex'
import { districtManagement } from '../api/index'
import { excelDownload } from '../../../util/excel'
import DargList from './dargList.vue'
export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  components: { DargList },
  data() {
    const phoneValidator = (rule, value, callback) => {
      let reg = /^1[0-9]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('手机号码有误，请重填'))
      }
      callback()
    }
    const idCardValidator = (rule, value, callback) => {
      if (!value) {
        return callback()
      }
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (reg.test(value) === false) {
        callback(new Error('请正确填写身份证'))
      }
      callback()
    }
    const isChn = (rule, value, callback) => {
      if (value === '') {
        return callback()
      }
      var reg = /^[\u4E00-\u9FA5]+$/
      if (!reg.test(value)) {
        callback(new Error('请输入中文'))
      }
      callback()
    }
    const isNumber = (rule, value, callback) => {
      if (value === '') {
        return callback()
      }
      var reg = /^[0-9]*$/
      if (!reg.test(value)) {
        callback(new Error('请输入纯数字'))
      }
      callback()
    }
    //--
    const validator = (rule, value, callback) => {
      // var reg = /[^\0-9\u4E00-\u9FA5]/g;
      var patrn =
        /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im
      if (patrn.test(value)) {
        // 如果包含特殊字符返回false
        callback(new Error('请不要输入特殊符号'))
      }
      // if (reg.test(value)) {
      //   callback(new Error('请输入10个以内中文或数字'));
      // }
      callback()
    }

    const pureNumber = (rule, value, callback) => {
      if (value === '') {
        return callback()
      }
      var reg = /^[0-9]*$/
      if (!reg.test(value)) {
        callback(new Error('请输入纯数字'))
      }
      callback()
    }

    const tenthsDigit = (rule, value, callback) => {
      const reg = /^[0-9]+([.][0-9]{1}){0,1}$/
      if (!reg.test(value)) {
        callback(new Error('请输入正确的数字'))
      }
      callback()
    }

    const twoDecimalPlaces = (rule, value, callback) => {
      var reg = /^\d+(\.\d{0,2})?$/

      if (!value) {
        callback()
      }

      if (!reg.test(value)) {
        callback(new Error('请输入正确的数字'))
      }
      callback()
    }
    return {
      sortby: false,
      defaultProps: {
        children: 'childrens',
        label: 'categoryName'
      },
      filterText: '',
      treeData: [],
      addOperatorList: [],
      dialogCategoryVisible: false,
      dialogImageUrl: '',
      fileList: [],
      creditLevelOptions: [
        {
          label: '1',
          value: '1'
        },
        {
          label: '2',
          value: '2'
        },
        {
          label: '3',
          value: '3'
        }
      ],
      imageUrl: '',
      addForm: {
        operationType: 'add',
        miId: '', // 商户管理主键id
        merchantName: '', // 商户名称
        creditLevel: '', // 商户名称
        sex: 1, // 性别 0 女 1 男
        idNumber: '', // 身份证号
        phone: '', // 手机号（联系方式）
        marketId: '', // 市场id
        depositBank: '', // 开户行
        accountName: '', // 户名
        cardNumber: '', // 卡号
        remark: '', // 备注
        headPortraitFile: '', // 头像
        businessLicensesFile: [], // 经营执照数据 '相对地址1','相对地址2'
        dnpMerchantCategoryInfoList: []
      },
      dnpMerchantCategoryInfoList: [],
      formLabelWidth: '100px',
      dialogFormVisible: false,
      rulesr: {
        merchantName: [
          { required: true, message: '请输入鲜道赢档主姓名', trigger: 'blur' },
          { validator: isChn, trigger: 'blur' }
        ],
        idNumber: [
          // {required: true, message: '请正确填写身份证', trigger: 'blur'},
          { validator: idCardValidator, trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '手机号码有误，请重填', trigger: 'blur' },
          { validator: phoneValidator, trigger: 'blur' }
        ],
        depositBank: [{ validator: isChn, trigger: 'blur' }],
        accountName: [{ validator: isChn, trigger: 'blur' }],
        cardNumber: [
          { validator: isNumber, trigger: 'blur' },
          { min: 10, max: 20, message: '长度在 10 到 20 个字符', trigger: 'blur' }
        ]
      },
      binding: false,
      accountAndMerchant: true,
      list: [],
      bindingaccount: {},
      merchantName: '', //商户姓名
      phone: '',
      active: 0,
      dnpMerchantInfoList: [],
      dialogMerchant: false,
      checkList: '',
      nextstep: true,
      page: {
        current_page: 1,
        page_size: 10
      },
      merList: {},
      total: 0,
      gearList: [],
      //--入驻
      businessTypeList: [],
      srid: false,
      labelWidth: '150px',
      isdisabledFn: false,
      dialogVisible: false,
      creatRegionOption: 'peer',
      optionFlag: false,
      options: [
        {
          id: 'peer',
          label: '新建区域'
        },
        {
          id: 'lower',
          label: '新建档口号'
        }
      ],
      labelList: [],
      form: {
        remarks: '',
        buildingCode: '',
        regionName: '',
        floorage: '',
        floorsTotalNum: '',
        //-------  档口
        regionId: '',
        businessTypeId: '',
        number: '',
        floorsNum: '',
        dnpLabelList: [],
        coefficient: '',
        premiseIndex: '',
        watermeterModel: '',
        watermeterNo: '',
        electricityModel: '',
        electricityNo: '',
        twoDecimalPlaces: ''
      },
      floorsTotalNum: '',
      isEdit: false,
      maxPremiseIndex: 0,
      rules: {
        buildingCode: [{ validator: validator, trigger: 'blur' }],
        regionName: [
          { required: true, message: '请填写区域名称', trigger: 'blur' },
          { validator: validator, trigger: 'blur' }
        ],
        floorage: [{ validator: twoDecimalPlaces, trigger: 'blur' }],
        floorsTotalNum: [{ validator: pureNumber, trigger: 'blur' }],
        businessTypeId: [{ required: true, message: '请选择所属类型', trigger: 'change' }],
        number: [
          { required: true, message: '请输入档口号', trigger: 'blur' }
          // {validator: validator, trigger: 'blur'},
        ],
        coefficient: [
          { required: true, message: '请输入算费系数', trigger: 'blur' },
          { validator: tenthsDigit, trigger: 'blur' }
        ]
      },
      activeName: 'first',
      radio: '按类型',
      regionId: '', //档口id
      regionList: [],
      region: [],
      viewArea: [],
      contractTempleteNum: '',
      contractList: [],
       dialogVisibler:false,
      list: [],
      totalTemporaryNum: 0, //快到期档口数量
      totalSignFloorage: 0, //已签面积
      talSignNum: 0, //已签档口数
      totalFloorage: 0, //总面积
      totalNum: 0, //档口总数
      nosignedStall: 0, //未签订档口数量
      noFloorage: 0, //未签订档口面积
      // percentageOfstallArea: 0, //档口面积百分比
      percentageOfsignedArea: 0, //已签面积百分比
      PercentageSignFloorage: 0 //已签订档口面积百分比
    }
  },
  computed: {
    ...mapState({
      marketId: (state) => state.marketId
    }),
    isPeer() {
      return this.creatRegionOption === 'peer'
    },
    title() {
      return this.isEdit ? '新增区域档口>编辑区域档口' : '新增区域/档口'
    }
  },
  filters: {
    getdecimal(val){
      let sum=parseFloat(val).toFixed(2)
      return sum
    }
  },
  watch: {
    filterText(val) {
      this.$refs.treePriceParams.filter(val)
    },
    name(val) {
      if (val === 'contractStatus') {
        this.getlistAll()
        this.getMarketRegion()
        this.getGoodType()
      }
    }
  },
  methods: {
    //详情
    getDetails(row) {
      this.$router.push({
        name: 'details',
        query: { data: row }
      })
    },
    //排序
    dargList(val) {
      this.sortby = val
      if (!val) {
        this.getlistAll()
        this.getMarketRegion()
      }
    },
    getSortby() {
      this.sortby = true
    },
    //删除档口
    removeChide(data) {
      this.$confirm('此操作将会解绑档口, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delGearNumberData(data.mrgId)
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    /**
     * @Description: 删除档口编号
     * @param mrgId
     * @return
     * @author
     * @date 2020/9/21
     */
    delGearNumberData(mrgId) {
      let params = {
        mrgId
      }
      districtManagement.DEL_REGION_GEAR_NUMBER(params).then((res) => {
        let {
          error_code,
          results: { msg }
        } = res.data
        if (error_code !== 200) {
          return this.$message({
            message: msg,
            type: 'error'
          })
        }
        this.getContractByRegionView()
        this.getContractByGearView()
        this.$message({
          message: '删除成功',
          type: 'success'
        })
      })
    },
    saveMerchantRetreatRear(row) {
      this.$confirm('此操作将会退档, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: '提交中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          const { id } = row
          console.log(row)
          districtManagement
            .SAVE_MERCHANT_RETREAT_GEAR({ id })
            .then((res) => {
              this.getContractByRegionView()
              this.getContractByGearView()
              loading.close()
            })
            .catch((err) => {
              loading.close()
            })
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //--退档
    // 删除图片
    handleRemove(file) {
      this.fileList = this.fileList.filter((item) => item.url !== file.url)
    },
    //预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisibler = true
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.addForm.marketId = this.marketId

          try {
            for (let i = 0; i < this.fileList.length; i++) {
              if (this.fileList[i].url) {
                const urlData = await this.convertImgToBase64(this.fileList[i].url)
                console.log(this.addForm.businessLicensesFile)
                try {
                  this.addForm.businessLicensesFile[i] = urlData
                } catch (e) {
                  console.log(e, 6666666)
                }
              }
            }
          } catch (e) {
            console.log(e)
          }
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          districtManagement
            .SAVE_MERCHANT_INFO(this.addForm)
            .then((res) => {
              loading.close()
              let { error_code } = res.data
              if (error_code === 200) {
                this.$message.success('添加成功')
                this.getMerchantr()
                this.beforeClose()
              }
            })
            .catch((err) => {
              loading.close()
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //  删除标签
    handleCloser(index) {
      this.dnpMerchantCategoryInfoList.splice(index, 1)
      this.$refs.treePriceParams.setCheckedNodes(this.dnpMerchantCategoryInfoList)
    },
    // 获取树类型结构
    getGoodType() {
      districtManagement.getGoodCategoryTree().then((response) => {
        this.options = response.data.results.filter((item) => item.categoryName !== '其他')
        this.options.forEach((item) => {
          item.childrens.forEach((categoryItem) => {
            categoryItem.childrens.forEach((children) => {
              delete children.childrens
            })
          })
        })

        let categoryTree = JSON.parse(JSON.stringify(this.options))
        categoryTree.forEach((item) => {
          item.disabled = true
          item.childrens.forEach((categoryItem) => {
            categoryItem.disabled = true
          })
        })
        this.treeData = categoryTree
      })
    },
    submitTag() {
      this.dialogCategoryVisible = false
      if (this.isAddForm) {
        this.addForm.dnpMerchantCategoryInfoList = [...this.dnpMerchantCategoryInfoList]
      } else {
        //  删除
        let spliceList = []
        this.addForm.dnpMerchantCategoryInfoList.forEach((item) => {
          let flag = this.dnpMerchantCategoryInfoList.some(
            (data) => item.categoryId === data.categoryId
          )
          if (!flag) {
            spliceList.push(item)
          }
        })
        console.log(spliceList)

        spliceList.forEach((item) => {
          let idx
          this.addForm.dnpMerchantCategoryInfoList.forEach((data, index) => {
            if (item.categoryId === data.categoryId) {
              if (data.status) {
                data.status = 'N'
              } else {
                idx = index
              }
            }
          })
          if (idx !== undefined) {
            this.addForm.dnpMerchantCategoryInfoList.splice(idx, 1)
          }
        })
        // 增加
        let addList = this.dnpMerchantCategoryInfoList.filter((item) => !item.status)
        //  去掉原先新增加
        let spliceOldData = this.addForm.dnpMerchantCategoryInfoList.filter((item) => item.status)
        // 合并
        this.addForm.dnpMerchantCategoryInfoList = [...spliceOldData, ...addList]
      }
    },
    // 选择 分类
    handlePriceClick(data, checked, node) {
      let { dnpMerchantCategoryInfoList } = this
      if (checked) {
        if (dnpMerchantCategoryInfoList.length >= 5) {
          this.$refs.treePriceParams.setCheckedNodes(dnpMerchantCategoryInfoList)
          return this.$message.error('最多选择5种品类')
        }
        this.dnpMerchantCategoryInfoList.push({
          categoryId: data.categoryId,
          categoryName: data.categoryName
        })
      } else {
        this.dnpMerchantCategoryInfoList = dnpMerchantCategoryInfoList.filter(
          (item) => item.categoryId !== data.categoryId
        )
      }
    },
    // 过滤分类
    filterNode(value, data) {
      if (!value) return true
      if (!value) return true
      return data.categoryName.indexOf(value) !== -1
    },
    // 超出上传图片
    handleExceed(files, fileList) {
      console.log(files, fileList, this.fileList)
    },
    //图片上传前
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        return this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      if (!isJPG && !isPNG) {
        return this.$message.error('请上传正确的图片类型')
      }
      if (this.fileList.length >= 3) {
        return
      }
      this.fileList.push({
        name: file.name,
        url: URL.createObjectURL(file)
      })
    },
    openAddTag() {
      this.dialogCategoryVisible = true
      if (this.isAddForm) {
        this.dnpMerchantCategoryInfoList = [...this.addForm.dnpMerchantCategoryInfoList]
      } else {
        // 状态正常的给添加列表
        this.dnpMerchantCategoryInfoList = this.addForm.dnpMerchantCategoryInfoList.filter(
          (item) => item.status !== 'N'
        )
      }
      this.$nextTick(() => {
        this.$refs.treePriceParams.setCheckedNodes(this.dnpMerchantCategoryInfoList)
      })
    },
    // 图片转base64
    convertImgToBase64(url, outputFormat) {
      return new Promise((resolve, reject) => {
        var canvas = document.createElement('CANVAS')
        var ctx = canvas.getContext('2d')
        var img = new Image()
        img.setAttribute('crossOrigin', 'anonymous')
        img.src = url
        img.onload = function () {
          var width = img.width
          var height = img.height
          // 按比例压缩2倍
          // var rate = (width < height ? width / height : height / width) /2;
          // canvas.width = width * rate;
          canvas.width = width
          // canvas.height = height * rate;
          canvas.height = height
          ctx.drawImage(
            img,
            0,
            0,
            width,
            height,
            0,
            0,
            // width * rate,
            width,
            height
            // height * rate
          )
          var dataURL = canvas.toDataURL(outputFormat || 'image/png')
          console.log(dataURL)
          resolve(dataURL)
        }
      })
    },
    //上传图片前
    async beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        return this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      if (!isJPG && !isPNG) {
        return this.$message.error('请上传正确的图片类型')
      }
      this.imageUrl = URL.createObjectURL(file)
      this.addForm.headPortraitFile = await this.convertImgToBase64(this.imageUrl)
    },
    beforeClose(done) {
      this.dialogFormVisible = false
      this.addForm = {
        operationType: 'add',
        miId: '', // 商户管理主键id
        merchantName: '', // 商户名称
        sex: 1, // 性别 0 女 1 男
        idNumber: '', // 身份证号
        phone: '', // 手机号（联系方式）
        marketId: '', // 市场id
        depositBank: '', // 开户行
        accountName: '', // 户名
        cardNumber: '', // 卡号
        remark: '', // 备注
        headPortraitFile: '', // 头像
        businessLicensesFile: [], // 经营执照数据 '相对地址1','相对地址2'
        dnpMerchantCategoryInfoList: []
      }
      this.fileList = []
      this.imageUrl = ''
      if (done) {
        done()
      }
    },
    getMerchants() {
      this.dialogFormVisible = true
    },
    //--新增商户
    getBindingaccount(row) {
      this.active = 1
      this.binding = true
      this.bindingaccount.mrgId = row.mrgId
      this.bindingaccount.regionId = row.regionId
      this.accountAndMerchant = false
      this.dialogMerchant = true
      this.getGearlistPhone()
    },
    handleClose() {
      this.merList = {}
      this.phone = ''
      this.merchantName = ''
      this.dialogMerchant = false
      this.nextstep = true
      this.active = 0
      this.binding = false
      this.getContractByRegionView()
      this.getContractByGearView()
    },
    getCompletebinding() {
      if (this.accountAndMerchant) {
        this.merList.contractStatus = 'N'
        districtManagement
          .DNP_ADD_MERCHANT_SETTLE_INFO({ ...this.merList })
          .then((result) => {
            this.getContractByRegionView()
            this.getContractByGearView()
            this.handleClose()
            this.$message({
              message: '入驻成功!',
              type: 'success'
            })
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.bindingaccount.contractStatus = 'N'
        districtManagement
          .DNP_ADD_BIND_REGION_GEAR_NUMBER({ ...this.bindingaccount })
          .then((result) => {
            this.getContractByRegionView()
            this.getContractByGearView()
            this.$message({
              message: '绑定成功!',
              type: 'success'
            })
            this.handleClose()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    getNobinding() {
      this.handleClose()
    },
    getNexstep() {
      this.active = 1
    },
    selectable(row) {
      if (row.bindStatus !== 'Y') {
        return true
      }
    },
    selectionChange(val) {
      if (val.length > 1) {
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(val.pop())
      }
      if (this.accountAndMerchant) {
        if (val.length > 0) {
          this.merList.gid = val[0].gid
          this.merList.uid = val[0].uid
        } else {
          this.merList.gid = ''
          this.merList.uid = ''
        }
      } else {
        if (val.length > 0) {
          this.binding = false
          this.bindingaccount.gid = val[0].gid
          this.bindingaccount.uid = val[0].uid
        } else {
          this.binding = true
          this.bindingaccount.gid = ''
          this.bindingaccount.uid = ''
        }
      }
    },
    handleSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(val.pop())
      }
      if (val.length > 0) {
        this.nextstep = false
        this.merList.miId = val[0].miId
        this.merList.marketId = val[0].marketId
        this.merList.uid = val[0].uid
      } else {
        this.merList = val
        this.nextstep = true
      }
    },
    getReset() {
      this.phone = ''
      this.merchantName = ''
      this.getMerchantr()
    },
    getMerchantsettled(row, item) {
      this.dialogMerchant = true
      this.merList.mrgId = row.mrgId
      this.merList.regionId = row.regionId
      this.merList.regionName = item.regionName
      this.getMerchantr()
    },
    getMerchantr() {
      let obj = {
        phone: this.phone,
        merchantName: this.merchantName,
        ...this.page
      }
      districtManagement
        .DNP_FIND_MERCHANT_INFO_LIST({ ...obj })
        .then((result) => {
          this.dnpMerchantInfoList = result.data.results.dnpMerchantInfoList
          this.total = result.data.results.total
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getGearlistPhone() {
      const params = {
        ...this.page,
        phone: this.phone
      }
      districtManagement
        .DNP_GEAR_LIST_OF_PHONE({ ...params })
        .then((result) => {
          this.gearList = result.data.results.gearList
          this.total = result.data.results.total
        })
        .catch((err) => {})
    },
    getMerchantInfoList() {
      if (this.active === 1) {
        this.getGearlistPhone()
      } else {
        this.getMerchantr()
      }
    },
    handleSizeChange(size) {
      this.page.page_size = size
      if (this.active === 1) {
        this.getGearlistPhone()
      } else {
        this.getMerchantr()
      }
    },
    handleCurrentChange(current) {
      this.page.current_page = current
      if (this.active === 1) {
        this.getGearlistPhone()
      } else {
        this.getMerchantr()
      }
    },
    //---入驻
    //编辑档口
    updateChildren(data) {
      this.srid = true
      this.getLabelList()
      this.getBusinessTypeList()
      const obj = {
        regionId: data.regionId
      }
      districtManagement.RequestMapping({ ...obj }).then((res) => {
        console.log(res.data.chargeNum, '====res.data.results')
        if (res.data.results > 0) {
          this.isdisabledFn = true
        } else {
          this.isdisabledFn = false
        }
      })
      districtManagement.getMaxPremiseIndex().then((res) => {
        if (res.data && res.data.error_code === 200 && res.data.status === 'Y') {
          this.maxPremiseIndex = res.data.results
        }
      })

      this.isEdit = true
      this.creatRegionOption = 'lower'
      const { mrgId } = data
      this.getNumberInfo(mrgId)
      this.dialogVisible = true
    },
    getNumberInfo(mrgId) {
      districtManagement.GET_REGION_GEAR_NUMBER_INFO({ mrgId }).then((res) => {
        if (res.data.results.chargeNum > 0) {
          this.isdisabledFn = true
        } else {
          this.isdisabledFn = false
        }
        const numberInfo = res.data.results
        let {
          businessTypeId,
          floorsTotalNum,
          coefficient,
          dnpLabelList,
          floorage,
          number,
          floorsNum,
          mrgId,
          regionId,
          remarks,
          premiseIndex,
          id,
          watermeterModel,
          watermeterNo,
          electricityModel,
          electricityNo
        } = numberInfo
        dnpLabelList = dnpLabelList.map((item) => item.labelId)
        this.form = {
          ...this.form,
          businessTypeId,
          floorsTotalNum,
          coefficient,
          dnpLabelList,
          floorage,
          floorsNum,
          mrgId,
          regionId,
          remarks,
          number,
          premiseIndex,
          id,
          watermeterModel,
          watermeterNo,
          electricityModel,
          electricityNo
        }

        this.floorsTotalNum = floorsTotalNum
      })
    },
    //--
    //新增档口
    append() {
      this.srid = false
      this.getLabelList()
      this.getBusinessTypeList()
      this.creatRegionOption = 'lower'
      this.optionFlag = true
      this.dialogVisible = true
      this.isdisabledFn = false
      if (this.regionId === '') {
        this.srid = false
      }
      districtManagement.getMaxPremiseIndex().then((res) => {
        if (res.data && res.data.error_code === 200 && res.data.status === 'Y') {
          this.maxPremiseIndex = res.data.results
        }
      })
    },
    //  获取商业类型
    getBusinessTypeList() {
      districtManagement.GET_BUSINESS_TYPE_LIST().then((res) => {
        this.businessTypeList = res.data.results
      })
    },
    getSeleRid(key) {
      this.region.forEach((item) => {
        if (item.regionId === key) {
          this.floorsTotalNum = item.floorsTotalNum
        }
      })
    },
    // 获取标签
    getLabelList() {
      districtManagement.GET_REGION_LABEL_LIST({ page_size: 1000, current_page: 1 }).then((res) => {
        let { dnpLabelList } = res.data.results
        this.labelList = dnpLabelList
      })
    },
    //---档口
    //导出
    exportToExcel() {
      marketplan
        .exportContractInfoByRegion({
          regionId: this.regionId,
          contractTempleteNum: this.contractTempleteNum
        })
        .then((result) => {
          this.$notify({
            title: '导出中',
            message: '正在导出中.....',
            type: 'success'
          })
          excelDownload(result)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //删除区域
    remove(item) {
      this.$confirm('此操作将会解绑档口, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRegionData(item.regionId)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    delRegionData(regionId) {
      let params = {
        regionId
      }
      districtManagement.DEL_REGION_INFO(params).then((res) => {
        let {
          error_code,
          results: { msg }
        } = res.data
        if (error_code !== 200) {
          return this.$message({
            message: msg,
            type: 'error'
          })
        }
        this.getContractByRegionView()
        this.getContractByGearView()
        this.$message({
          message: '删除成功',
          type: 'success'
        })
      })
    },
    //  编辑打开更改输入框
    updateKeyChildren(key, data) {
      const obj = {
        regionId: data.regionId
      }
      districtManagement.RequestMapping({ ...obj }).then((res) => {
        console.log(res.data.chargeNum, '====res.data.results')
        if (res.data.results > 0) {
          this.isdisabledFn = true
        } else {
          this.isdisabledFn = false
        }
      })

      districtManagement.getMaxPremiseIndex().then((res) => {
        if (res.data && res.data.error_code === 200 && res.data.status === 'Y') {
          this.maxPremiseIndex = res.data.results
        }
      })

      this.isEdit = true
      let res = {
        marketId: this.marketId,
        mid: this.marketId,
        regionId: key
      }
      districtManagement
        .getSELECT_MARK_REGION({ ...res })
        .then((result) => {
          // 编辑区域
          const {
            remarks,
            buildingCode,
            regionName,
            floorage,
            floorsTotalNum,
            regionId,
            premiseIndex,
            watermeterModel,
            watermeterNo,
            electricityModel,
            electricityNo,
            id
          } = result.data.results[0]
          this.form = {
            ...this.form,
            remarks,
            buildingCode,
            regionName,
            floorage,
            floorsTotalNum,
            regionId,
            id,
            premiseIndex,
            watermeterModel,
            watermeterNo,
            electricityModel,
            electricityNo
          }
          this.creatRegionOption = 'peer'
          this.dialogVisible = true
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //---新增区域
    //弹窗确定按钮
    addRegion(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { creatRegionOption, optionFlag, isEdit } = this

          if (isEdit) {
            return creatRegionOption === 'peer' ? this.editRegion() : this.editGearNumber()
          }
          optionFlag || creatRegionOption !== 'peer'
            ? this.addGearNumberData()
            : this.addRegionData()
        }
      })
    },
    addGearNumberData() {
      let {
        regionId,
        businessTypeId,
        number,
        floorsNum,
        dnpLabelList,
        coefficient,
        floorage,
        remarks,
        premiseIndex,
        watermeterModel,
        watermeterNo,
        electricityModel,
        electricityNo
      } = this.form
      dnpLabelList = dnpLabelList.map((item) => {
        return {
          labelId: item
        }
      })
      const params = {
        regionId,
        businessTypeId,
        number,
        floorage,
        floorsNum,
        dnpLabelList,
        coefficient,
        remarks,
        premiseIndex,
        watermeterModel,
        watermeterNo,
        electricityModel,
        electricityNo,
        parentId: this.parentId
      }
      districtManagement.ADD_REGION_GEAR_NUMBER(params).then((res) => {
        let { error_code, results } = res.data
        if (error_code !== 200) {
          return this.$message({
            message: results,
            type: 'error'
          })
        }

        this.close()
        this.$message({
          message: '添加成功',
          type: 'success'
        })
        this.getContractByRegionView()
        this.getContractByGearView()
      })
    },
    addRegionData() {
      const {
        remarks,
        buildingCode,
        regionName,
        floorage,
        floorsTotalNum,
        regionId,
        watermeterModel,
        watermeterNo,
        electricityModel,
        electricityNo
      } = this.form
      districtManagement
        .ADD_REGION_INFO({
          remarks,
          buildingCode,
          regionName,
          floorage,
          watermeterModel,
          watermeterNo,
          electricityModel,
          electricityNo,
          floorsTotalNum
        })
        .then((res) => {
          let { error_code, results } = res.data
          if (error_code !== 200) {
            return this.$message({
              message: results,
              type: 'error'
            })
          }
          this.close()
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.getContractByRegionView()
          this.getContractByGearView()
        })
    },
    editRegion() {
      const {
        regionId,
        regionName,
        remarks,
        buildingCode,
        floorage,
        floorsTotalNum,
        watermeterModel,
        watermeterNo,
        electricityModel,
        electricityNo,
        id
      } = this.form
      const params = {
        regionId,
        regionName,
        remarks,
        buildingCode,
        floorage,
        floorsTotalNum,
        watermeterModel,
        watermeterNo,
        electricityModel,
        electricityNo,
        id
      }

      districtManagement
        .DIET_REGION_INFO(params)
        .then((res) => {
          let {
            error_code,
            results: { msg }
          } = res.data
          if (error_code !== 200) {
            return this.$message({
              message: msg,
              type: 'error'
            })
          }
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.close()
          this.getContractByRegionView()
          this.getContractByGearView()
        })
        .catch((err) => {
          data.label = regionName
          this.reloadTree()
          this.$message({
            type: 'error',
            message: '编辑失败'
          })
        })
    },
    editGearNumber(data, label) {
      let {
        regionId,
        businessTypeId,
        number,
        floorage,
        floorsNum,
        dnpLabelList,
        coefficient,
        remarks,
        mrgId,
        premiseIndex,
        watermeterModel,
        watermeterNo,
        electricityModel,
        electricityNo,
        id
      } = this.form
      dnpLabelList = dnpLabelList.map((item) => ({ labelId: item }))
      const params = {
        regionId,
        businessTypeId,
        number,
        floorage,
        floorsNum,
        dnpLabelList,
        coefficient,
        mrgId,
        remarks,
        premiseIndex,
        watermeterModel,
        watermeterNo,
        electricityModel,
        electricityNo,
        id
      }
      districtManagement
        .DIET_REGION_GEAR_NUMBER(params)
        .then((res) => {
          let {
            error_code,
            results: { msg }
          } = res.data
          if (error_code !== 200) {
            return this.$message({
              message: msg,
              type: 'error'
            })
          }

          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.close()
          this.getContractByRegionView()
          this.getContractByGearView()
        })
        .catch((err) => {})
    },
    close() {
      this.form = {
        remarks: '',
        buildingCode: '',
        regionName: '',
        floorage: '',
        floorsTotalNum: '',
        //-------  档口
        regionId: '',
        businessTypeId: '',
        number: '',
        floorsNum: '',
        labelList: [],
        coefficient: '',
        watermeterModel: '',
        watermeterNo: '',
        electricityModel: '',
        electricityNo: ''
      }
      this.form.regionId = this.regionId
      this.isEdit = false
      this.dialogVisible = false
    },
    getNewarea() {
      this.isdisabledFn = false
      this.creatRegionOption = 'peer'
      this.dialogVisible = true
    },
    //---
    getBackground(state) {
      if (state === '已签订') {
        return 'background: #fc7719'
      } else if (state === '未签订') {
        return 'background:#F7BA1E'
      } else if (state === '快到期') {
        return 'background:#722ED1'
      } else {
        return 'background:#FFFFFF'
      }
    },
    getStatusColro(state) {
      if (state === '已签订') {
        return '#fc7719'
      } else if (state === '未签订') {
        return '#F7BA1E'
      } else if (state === '快到期') {
        return '#722ED1 '
      }
    },
    async getContractByRegionView() {
      let obj = {
        regionId: this.regionId,
        contractTempleteNum: this.contractTempleteNum
      }
      let res = await marketplan.getContractByRegionView({ ...obj })
      this.regionList = res.data.results.regionList
      this.list = res.data.results.businessTypeList
      this.totalTemporaryNum = res.data.results.totalTemporaryNum
      this.totalSignFloorage = res.data.results.totalSignFloorage
      this.talSignNum = res.data.results.totalSignNum
      this.totalFloorage = res.data.results.totalFloorage
      this.totalNum = res.data.results.totalNum
      this.nosignedStall = this.totalNum - this.talSignNum
      this.noFloorage = this.totalFloorage - this.totalSignFloorage
      // this.percentageOfstallArea =
      //   Number((this.noFloorage / this.totalFloorage) * 100).toFixed(0) === 'NaN'
      //     ? 0
      //     : Number((this.noFloorage / this.totalFloorage) * 100).toFixed(0)
      this.percentageOfsignedArea =
        Number((this.talSignNum / this.totalFloorage) * 100).toFixed(0) === 'NaN'
          ? 0
          : Number((this.talSignNum / this.totalFloorage) * 100).toFixed(0)
      this.PercentageSignFloorage =
        Number((this.totalSignFloorage / this.totalFloorage) * 100).toFixed(0) === 'NaN'
          ? 0
          : Number((this.totalSignFloorage / this.totalFloorage) * 100).toFixed(0)
    },
    async getContractByGearView() {
      let obj = {
        regionId: this.regionId,
        contractTempleteNum: this.contractTempleteNum
      }
      let res = await marketplan.getContractByGearView({ ...obj })
      this.viewArea = res.data.results.regionList
    },
    async getregionSelect() {
      this.getContractByGearView()
      this.getContractByRegionView()
    },
    async getContract() {
      this.getContractByGearView()
      this.getContractByRegionView()
    },
    getlistAll() {
      let obj = {
        requestNo: new Date().getTime(),
        isDecode: true
      }
      marketplan
        .getlistAll({ ...obj })
        .then((result) => {
          console.log(result)
          this.contractList = result.data.data
          this.contractTempleteNum = this.contractList[0].number
          this.getContractByRegionView()
          this.getContractByGearView()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getMarketRegion() {
      let params = {
        mid: this.marketId
      }
      let res = await marketplan.SELECT_MARK_REGION(params)
      this.region = res.data.results
    }
  }
}
</script>
<style lang="scss">
.plan {
   .item {
    margin: 4px;
    cursor: pointer;
  }
  .areaText {
    font-size: 16px;
  }
  .dataOverview {
    margin-bottom: 20px;
  }
  .plancard-head {
    font-size: 15px;
  }
  .central {
    padding-left: 20px;
  }
  .textlattice {
    padding-left: 20px;
  }
  .lattice {
    width: 200px;
    height: 90px;

    // border: 1px dashed;
    margin-top: 20px;
    padding: 10px;
    // background: #3bb96e;
    color: #000;
    display: inline-block;
    margin-left: 20px;
  }
  .merchantName {
    height: 30px;
    line-height: 30px;
  }
  .stallviewtext-left {
    width: 80px;
    text-align: left;
  }
  .plan-Area {
    margin-left: 30px;
  }
  .plan-quantity {
    margin-right: 30px;
    cursor: pointer;
  }
  .areaview {
    margin-top: 20px;
  }
  .plan-green {
    width: 20px;
    height: 20px;
    background: #00b42a;
    display: inline-block;
  }
  .plan-orange {
    width: 20px;
    height: 20px;
    background: #fc7719;
    display: inline-block;
  }
  .plan-purple {
    width: 20px;
    height: 20px;
    background: #c000f5;
    display: inline-block;
  }
  .notCheckedin {
    width: 20px;
    height: 20px;
    border: 1px solid;
    display: inline-block;
  }
  .plan-yellow {
    width: 20px;
    height: 20px;
    background: #e8fa0d;
    display: inline-block;
  }
  .plan-ash {
    width: 20px;
    height: 20px;
    background: #858580;
    display: inline-block;
  }
  .plancard {
    background: #f2f2f2;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
    width: 100%;
    white-space: normal;
  }
  .el-progress {
    width: 281px;
  }
  .demo-progress {
    margin-top: 20px;
  }
  .box {
    width: 400px;

    .top {
      text-align: center;
    }

    .left {
      float: left;
      width: 60px;
    }

    .right {
      float: right;
      width: 60px;
    }

    .bottom {
      clear: both;
      text-align: center;
    }

    .item {
      margin: 4px;
    }

    .left .el-tooltip__popper,
    .right .el-tooltip__popper {
      padding: 8px 10px;
    }
  }
}
</style>
<style lang='scss' scoped>
.contratMesO{
  display: flex;
  margin-bottom: 10px;
  flex: 1;
  justify-content: space-between;
  .m-r-20{
    width: 25%;
    span{
      font-weight: 600;
    }
  }
}
.plancardareaCard {
  width: 483px;
  display: inline-block;
  margin-right: 10px;
  margin-left: 30px;
  background: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px dashed;
}
</style>

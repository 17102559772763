var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plan" },
    [
      _c("div", { staticClass: "dataOverview" }, [
        _c("span", { staticStyle: { "font-weight": "600" } }, [
          _vm._v("数据总览"),
        ]),
        _c(
          "span",
          { staticClass: "plan-Area" },
          [
            _vm._v("区域 "),
            _c(
              "el-select",
              {
                on: {
                  change: function ($event) {
                    return _vm.getregionSelect()
                  },
                },
                model: {
                  value: _vm.regionId,
                  callback: function ($$v) {
                    _vm.regionId = $$v
                  },
                  expression: "regionId",
                },
              },
              [
                _c("el-option", { attrs: { value: "", label: "全部区域" } }, [
                  _vm._v("全部区域"),
                ]),
                _vm._l(_vm.region, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { value: item.regionId, label: item.regionName },
                  })
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm.activeName === "second"
          ? _c(
              "span",
              { staticClass: "plan-Area" },
              [
                _vm._v("使用类型 "),
                _c(
                  "el-select",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.getNatureofuse()
                      },
                    },
                    model: {
                      value: _vm.businessTypeId,
                      callback: function ($$v) {
                        _vm.businessTypeId = $$v
                      },
                      expression: "businessTypeId",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "全部", value: "" } }),
                    _vm._l(_vm.businessTypeList, function (row, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          value: row.businessTypeId,
                          label: row.businessTypeName,
                        },
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("el-card", [
        _c("div", [
          _c("div", { staticClass: "contratMesO" }, [
            _c("div", { staticClass: "m-r-20" }, [
              _c("span", [_vm._v("档口数量(个) : ")]),
              _vm._v(_vm._s(_vm.totalNum) + " "),
            ]),
            _c("div", { staticClass: "m-r-20" }, [
              _c("span", [_vm._v("档口面积(m²) :")]),
              _vm._v(" " + _vm._s(_vm._f("getdecimal")(_vm.totalFloorage))),
            ]),
            _c("div", { staticClass: "m-r-20" }, [
              _c("span", [_vm._v("已入驻档口数量(个) :")]),
              _vm._v(
                " " +
                  _vm._s(_vm.totalSettledNum) +
                  "(" +
                  _vm._s(_vm.percentageofstalls) +
                  "%)"
              ),
            ]),
          ]),
          _c("div", { staticClass: "contratMesO" }, [
            _c("div", { staticClass: "m-r-20" }, [
              _c("span", [_vm._v("未入住档口数量(个) :")]),
              _vm._v(" " + _vm._s(_vm.notsettled)),
            ]),
            _c("div", { staticClass: "m-r-20" }, [
              _c("span", [_vm._v("已入驻档口面积(m²) : ")]),
              _vm._v(
                _vm._s(_vm._f("getdecimal")(_vm.totalSettledFloorage)) +
                  "(" +
                  _vm._s(_vm.areaPercentage) +
                  "%)"
              ),
            ]),
            _c("div", { staticClass: "m-r-20" }, [
              _c("span", [_vm._v("未入住档口面积(m²) :")]),
              _vm._v(" " + _vm._s(_vm._f("getdecimal")(_vm.nototalFloorage))),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "areaview" },
        [
          _c(
            "el-card",
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "区域视图", name: "first" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "div",
                            { staticClass: "m-r-20" },
                            [
                              _vm._v(" 显示配置: "),
                              _c("el-radio", {
                                attrs: { label: "按类型" },
                                model: {
                                  value: _vm.radio,
                                  callback: function ($$v) {
                                    _vm.radio = $$v
                                  },
                                  expression: "radio",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._l(_vm.businessTypeList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticStyle: { display: "flex" } },
                              [
                                _c("div", {
                                  staticClass: "plan-green",
                                  style: _vm.getBackground(
                                    item.businessTypeName
                                  ),
                                }),
                                _c("div", { staticClass: "m-r-20" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.businessTypeName) +
                                      "(" +
                                      _vm._s(item.totalNum) +
                                      ")"
                                  ),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "divButton" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.getNewarea },
                              },
                              [_vm._v("新增区域")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getSortby()
                                  },
                                },
                              },
                              [_vm._v(" 排序 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.exportToExcel()
                                  },
                                },
                              },
                              [_vm._v("导出数据")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "plancard" },
                        _vm._l(_vm.regionList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "plancardareaCard" },
                            [
                              _c(
                                "el-row",
                                { staticClass: "row-areaText" },
                                [
                                  _c("el-col", { attrs: { span: 16 } }, [
                                    _c("div", { staticClass: "areaText" }, [
                                      _vm._v(_vm._s(item.regionName)),
                                    ]),
                                  ]),
                                  _c("el-col", { attrs: { span: 7 } }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit font-size c-989898",
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateKeyChildren(
                                            item.regionId,
                                            item
                                          )
                                        },
                                      },
                                    }),
                                    _c("i", {
                                      staticClass: "el-icon-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.remove(item)
                                        },
                                      },
                                    }),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { staticClass: "plancard-head" },
                                [
                                  _c("el-col", { attrs: { span: 5 } }, [
                                    item.buildingCode
                                      ? _c("div", [
                                          _vm._v(_vm._s(item.buildingCode)),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c("el-col", { attrs: { span: 9 } }, [
                                    _vm._v(
                                      "档口数量(个):" +
                                        _vm._s(item.regionTotalNum)
                                    ),
                                  ]),
                                  _c("el-col", { attrs: { span: 9 } }, [
                                    _vm._v(
                                      "总档口面积(m²):" +
                                        _vm._s(
                                          _vm._f("getdecimal")(
                                            item.regionTotalFloorage
                                          )
                                        )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "demo-progress" },
                                  _vm._l(
                                    item.regionBusinessTypeList,
                                    function (type, index) {
                                      return _c(
                                        "el-row",
                                        { key: index },
                                        [
                                          _c("el-col", { attrs: { span: 3 } }, [
                                            _vm._v(
                                              _vm._s(type.businessTypeName)
                                            ),
                                          ]),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 16 } },
                                            [
                                              _c("el-progress", {
                                                attrs: {
                                                  "stroke-width": 10,
                                                  color: _vm.getStatusColro(
                                                    type.businessTypeName
                                                  ),
                                                  "show-text": false,
                                                  percentage:
                                                    type.settledNum === 0 &&
                                                    type.totalNum === 0
                                                      ? 0
                                                      : parseInt(
                                                          (type.settledNum /
                                                            type.totalNum) *
                                                            100
                                                        ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("el-col", { attrs: { span: 5 } }, [
                                            _vm._v(
                                              _vm._s(
                                                type.settledNum +
                                                  "/" +
                                                  type.totalNum
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "档口视图", name: "second" } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c(
                          "div",
                          { staticClass: "m-r-20" },
                          [
                            _vm._v("显示配置: "),
                            _c("el-radio", {
                              attrs: { label: "按类型" },
                              model: {
                                value: _vm.radio,
                                callback: function ($$v) {
                                  _vm.radio = $$v
                                },
                                expression: "radio",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "margin-right": "20px",
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "plan-green",
                                staticStyle: { background: "#00b42a" },
                              }),
                              _c("div", [
                                _vm._v(
                                  " 已入驻(" + _vm._s(_vm.alreadySettled) + ")"
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticStyle: { display: "flex" } }, [
                            _c("div", { staticClass: "notCheckedin" }),
                            _c("div", [
                              _vm._v(" 未入驻(" + _vm._s(_vm.notsettled) + ")"),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._l(_vm.viewArea, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "plancard" },
                          [
                            _c(
                              "div",
                              { staticClass: "central" },
                              [
                                _c("div", { staticClass: "textlattice" }, [
                                  _vm._v(_vm._s(item.regionName)),
                                ]),
                                _vm._l(
                                  item.gearNumberList,
                                  function (row, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "lattice",
                                        style:
                                          row.settleStatus === "Y"
                                            ? "background:#00B42A"
                                            : "background:#ffffff",
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDetails(row)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "numberClss",
                                                staticStyle: {
                                                  "padding-top": "0",
                                                },
                                                attrs: { span: 10 },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: row.number,
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    row.number
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "buildingCoder",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(row.number)
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                style: row.number
                                                  ? "text-align: right;padding-top: 0"
                                                  : "text-align:left;padding-top: 0",
                                                attrs: { span: 13 },
                                              },
                                              [
                                                row.floorage
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(row.floorage) +
                                                          "m²"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "merchantName" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  position: "absolute",
                                                },
                                              },
                                              [_vm._v(_vm._s(row.merchantName))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "numberClss",
                                                staticStyle: {
                                                  "text-align": "left",
                                                  "padding-top": "0",
                                                },
                                                attrs: { span: 10 },
                                              },
                                              [
                                                row.businessTypeName
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.businessTypeName
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            row.labelName
                                              ? _c(
                                                  "el-col",
                                                  {
                                                    staticClass: "numberClss",
                                                    staticStyle: {
                                                      "text-align": "right",
                                                      "padding-top": "0",
                                                    },
                                                    attrs: { span: 6 },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(row.labelName) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "text-align": "right",
                                                  "padding-top": "0",
                                                },
                                                attrs: {
                                                  span: row.labelName ? 8 : 13,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-popover",
                                                  {
                                                    attrs: {
                                                      placement: "bottom-start",
                                                      title: "操作",
                                                      width: "200",
                                                      trigger: "hover",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-row",
                                                          [
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 12,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.updateChildren(
                                                                            row
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "修改档口"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 12,
                                                                },
                                                              },
                                                              [
                                                                row.settleStatus ===
                                                                  "Y" &&
                                                                row.gearBindStatus ===
                                                                  "N"
                                                                  ? _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          type: "text",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.getBindingaccount(
                                                                                row
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "绑定账号"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "el-row",
                                                          [
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 12,
                                                                },
                                                              },
                                                              [
                                                                row.settleStatus !==
                                                                "Y"
                                                                  ? _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          type: "text",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.getMerchantsettled(
                                                                                row,
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "商户入驻"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 12,
                                                                },
                                                              },
                                                              [
                                                                row.settleStatus ===
                                                                "Y"
                                                                  ? _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          type: "text",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.saveMerchantRetreatRear(
                                                                                row
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "退档 "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 12,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeChide(
                                                                            row
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticClass:
                                                          "operation",
                                                        style:
                                                          row.settleStatus ===
                                                          "Y"
                                                            ? "color: #ffffff"
                                                            : "color: #000",
                                                        attrs: {
                                                          slot: "reference",
                                                          type: "text",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [_vm._v("变更")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _c("div", { staticClass: "div2" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.append()
                                        },
                                      },
                                    },
                                    [_vm._v("新增档口")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getSortby()
                                        },
                                      },
                                    },
                                    [_vm._v("排序")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "商户入驻",
                    "before-close": _vm.handleClose,
                    visible: _vm.dialogMerchant,
                    width: "50%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogMerchant = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "merchantSteps" },
                    [
                      _c(
                        "el-steps",
                        {
                          attrs: {
                            active: _vm.active,
                            "align-center": "",
                            "finish-status": "success",
                          },
                        },
                        [
                          _c("el-step", { attrs: { title: "选择商户" } }),
                          _c("el-step", { attrs: { title: "绑定鲜道赢账号" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "condition" },
                    [
                      _vm.active !== 1
                        ? _c("el-input", {
                            attrs: { placeholder: "输入商户姓名搜素" },
                            model: {
                              value: _vm.merchantName,
                              callback: function ($$v) {
                                _vm.merchantName = $$v
                              },
                              expression: "merchantName",
                            },
                          })
                        : _vm._e(),
                      _c("el-input", {
                        class: _vm.active === 1 ? "input" : "",
                        attrs: {
                          placeholder:
                            _vm.active === 1
                              ? "输入手机号/登录编号搜索"
                              : "手机号搜索",
                        },
                        model: {
                          value: _vm.phone,
                          callback: function ($$v) {
                            _vm.phone = $$v
                          },
                          expression: "phone",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.getMerchantInfoList },
                        },
                        [_vm._v("查询")]
                      ),
                      _c("el-button", { on: { click: _vm.getReset } }, [
                        _vm._v("重置"),
                      ]),
                      _vm.active !== 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.getMerchants },
                            },
                            [_vm._v("新增商户")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "merchant-table" },
                    [
                      _vm.active !== 1
                        ? _c(
                            "el-table",
                            {
                              ref: "multipleTable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.dnpMerchantInfoList,
                                border: "",
                              },
                              on: {
                                "selection-change": _vm.handleSelectionChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  width: "55",
                                  selectable: _vm.selectable,
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "merchantName",
                                  label: "商户姓名",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "phone", label: "联系方式" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "sex", label: "性别" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("sex")(scope.row.sex)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  745555844
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "idNumber", label: "身份证号" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "merchantCategoryInfo",
                                  label: "经营品类",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.active === 1
                        ? _c(
                            "el-table",
                            {
                              ref: "multipleTable",
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.gearList, border: "" },
                              on: { "selection-change": _vm.selectionChange },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  width: "55",
                                  selectable: _vm.selectable,
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "phone", label: "登录手机号" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "userName", label: "系统用户" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "gearName", label: "档口名称" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        [
                          _c("el-pagination", {
                            attrs: {
                              "current-page": _vm.page.current_page,
                              "page-size": _vm.page.page_size,
                              layout: "total, prev, pager, next",
                              total: _vm.total,
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.handleCurrentChange,
                              "update:currentPage": function ($event) {
                                return _vm.$set(
                                  _vm.page,
                                  "current_page",
                                  $event
                                )
                              },
                              "update:current-page": function ($event) {
                                return _vm.$set(
                                  _vm.page,
                                  "current_page",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.active === 0
                    ? _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c("el-button", { on: { click: _vm.getNobinding } }, [
                            _vm._v("取消"),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.nextstep,
                              },
                              on: { click: _vm.getNexstep },
                            },
                            [_vm._v("下一步")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c("el-button", { on: { click: _vm.getNobinding } }, [
                            _vm._v("暂不绑定"),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", disabled: _vm.binding },
                              on: { click: _vm.getCompletebinding },
                            },
                            [_vm._v("完成绑定")]
                          ),
                        ],
                        1
                      ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              ref: "dialogVisible",
              staticClass: "dialogr",
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "550px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
                close: _vm.close,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": _vm.labelWidth,
                  },
                },
                [
                  !_vm.isEdit
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "层级目录：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: "", placeholder: "请选择" },
                              model: {
                                value: _vm.creatRegionOption,
                                callback: function ($$v) {
                                  _vm.creatRegionOption = $$v
                                },
                                expression: "creatRegionOption",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  disabled:
                                    _vm.optionFlag && item.id === "peer",
                                  label: item.label,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isPeer
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "楼栋编号：", prop: "buildingCode" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "选填，请填写楼栋编号",
                              disabled: _vm.isdisabledFn,
                            },
                            model: {
                              value: _vm.form.buildingCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "buildingCode", $$v)
                              },
                              expression: "form.buildingCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isPeer
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "regionName", label: "区域名称：" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "必填，请填写区域名称",
                              disabled: _vm.isdisabledFn,
                            },
                            model: {
                              value: _vm.form.regionName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "regionName", $$v)
                              },
                              expression: "form.regionName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isPeer
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "建筑面积：", prop: "floorage" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "选填，请填写建筑面积",
                              disabled: _vm.isdisabledFn,
                            },
                            model: {
                              value: _vm.form.floorage,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "floorage", $$v)
                              },
                              expression: "form.floorage",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isPeer
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "总层数：", prop: "floorsTotalNum" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "选填，请填写总层数",
                              disabled: _vm.isdisabledFn,
                            },
                            model: {
                              value: _vm.form.floorsTotalNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "floorsTotalNum", $$v)
                              },
                              expression: "form.floorsTotalNum",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isPeer
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选择区域：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.srid,
                              },
                              on: { change: _vm.getSeleRid },
                              model: {
                                value: _vm.form.regionId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "regionId", $$v)
                                },
                                expression: "form.regionId",
                              },
                            },
                            _vm._l(_vm.region, function (item) {
                              return _c("el-option", {
                                key: item.regionId,
                                attrs: {
                                  label: item.regionName,
                                  value: item.regionId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isPeer
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属类型：",
                            prop: "businessTypeId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.isdisabledFn,
                              },
                              model: {
                                value: _vm.form.businessTypeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "businessTypeId", $$v)
                                },
                                expression: "form.businessTypeId",
                              },
                            },
                            _vm._l(_vm.businessTypeListr, function (item) {
                              return _c("el-option", {
                                key: item.businessTypeId,
                                attrs: {
                                  label: item.businessTypeName,
                                  value: item.businessTypeId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isPeer
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "档口号：", prop: "number" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "必填，请填写编号",
                              disabled: _vm.isdisabledFn,
                            },
                            model: {
                              value: _vm.form.number,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "number",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.number",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isPeer
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选择楼层：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.floorsNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "floorsNum", $$v)
                                },
                                expression: "form.floorsNum",
                              },
                            },
                            _vm._l(_vm.floorsTotalNum, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isPeer
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选择标签：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { multiple: "", placeholder: "请选择" },
                              model: {
                                value: _vm.form.dnpLabelList,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dnpLabelList", $$v)
                                },
                                expression: "form.dnpLabelList",
                              },
                            },
                            _vm._l(_vm.labelList, function (item) {
                              return _c("el-option", {
                                key: item.labelId,
                                attrs: {
                                  label: item.labelName,
                                  value: item.labelId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isPeer
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            rules: [
                              {
                                required: true,
                                message: "请填写档口排序号",
                                trigger: "blur",
                              },
                            ],
                            prop: "premiseIndex",
                            label:
                              "档口排序号(最大" +
                              (_vm.maxPremiseIndex || 0) +
                              ")：",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "必填，请填写档口排序号" },
                            model: {
                              value: _vm.form.premiseIndex,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "premiseIndex", $$v)
                              },
                              expression: "form.premiseIndex",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isPeer
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            rules: [
                              {
                                required: true,
                                message: "请填写建筑面积",
                                trigger: "blur",
                              },
                            ],
                            prop: "floorage",
                            label: "建筑面积：",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "必填，请填写建筑面积" },
                            model: {
                              value: _vm.form.floorage,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "floorage", $$v)
                              },
                              expression: "form.floorage",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isPeer
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "coefficient", label: "算费系数：" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "必填，请填写算费系数" },
                            model: {
                              value: _vm.form.coefficient,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "coefficient", $$v)
                              },
                              expression: "form.coefficient",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "选填，请填写备注" },
                        model: {
                          value: _vm.form.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remarks", $$v)
                          },
                          expression: "form.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addRegion("form")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "before-close": _vm.beforeClose,
                title: "添加商户",
                visible: _vm.dialogFormVisible,
                width: "800px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: {
                    "label-width": _vm.formLabelWidth,
                    model: _vm.addForm,
                    rules: _vm.rulesr,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商户姓名:",
                                prop: "merchantName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "必填，请填写姓名" },
                                model: {
                                  value: _vm.addForm.merchantName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "merchantName", $$v)
                                  },
                                  expression: "addForm.merchantName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "性别:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w-100",
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.addForm.sex,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "sex", $$v)
                                    },
                                    expression: "addForm.sex",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "男", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "女", value: 0 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "身份证号:", prop: "idNumber" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "可选，请填写身份证号" },
                                model: {
                                  value: _vm.addForm.idNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "idNumber", $$v)
                                  },
                                  expression: "addForm.idNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系方式", prop: "phone" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "必填，请填写手机号" },
                                model: {
                                  value: _vm.addForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "phone", $$v)
                                  },
                                  expression: "addForm.phone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "开户行:", prop: "depositBank" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "选填，请填写开户行",
                                },
                                model: {
                                  value: _vm.addForm.depositBank,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "depositBank", $$v)
                                  },
                                  expression: "addForm.depositBank",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "户名:", prop: "accountName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "选填，请填写户名",
                                },
                                model: {
                                  value: _vm.addForm.accountName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "accountName", $$v)
                                  },
                                  expression: "addForm.accountName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "卡号:", prop: "cardNumber" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "选填，请填写卡号" },
                                model: {
                                  value: _vm.addForm.cardNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "cardNumber", $$v)
                                  },
                                  expression: "addForm.cardNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 10 } }, [
                        _c(
                          "div",
                          { staticClass: "header" },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  action: " ",
                                  "show-file-list": false,
                                  "before-upload": _vm.beforeAvatarUpload,
                                },
                              },
                              [
                                _vm.imageUrl
                                  ? _c("img", {
                                      staticClass: "avatar",
                                      attrs: { src: _vm.imageUrl },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "经营品类:" } },
                    [
                      _vm._l(
                        _vm.addForm.dnpMerchantCategoryInfoList,
                        function (tag, index) {
                          return tag.status !== "N"
                            ? _c(
                                "el-tag",
                                {
                                  key: index,
                                  staticClass: "m-r-20",
                                  attrs: { closable: "" },
                                  on: {
                                    close: function ($event) {
                                      return _vm.handleCloseTag(index, tag)
                                    },
                                  },
                                },
                                [
                                  [
                                    _vm._v(
                                      " " + _vm._s(tag.categoryName) + " "
                                    ),
                                  ],
                                ],
                                2
                              )
                            : _vm._e()
                        }
                      ),
                      _c("el-button", { on: { click: _vm.openAddTag } }, [
                        _vm._v("添加"),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "信用评分:" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.addForm.creditLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "creditLevel", $$v)
                            },
                            expression: "addForm.creditLevel",
                          },
                        },
                        _vm._l(_vm.creditLevelOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "营业执照:" } },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            "before-upload": _vm.beforeUpload,
                            "on-exceed": _vm.handleExceed,
                            "file-list": _vm.fileList,
                            action: "#",
                            limit: 3,
                            "list-type": "picture-card",
                            "auto-upload": true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "file",
                                fn: function (ref) {
                                  var file = ref.file
                                  return _c("div", {}, [
                                    _c("img", {
                                      staticClass:
                                        "el-upload-list__item-thumbnail",
                                      attrs: { src: file.url, alt: "" },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "el-upload-list__item-actions",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "el-upload-list__item-preview",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handlePictureCardPreview(
                                                  file
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-zoom-in",
                                            }),
                                          ]
                                        ),
                                        !_vm.disabled
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "el-upload-list__item-delete",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRemove(
                                                      file
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-delete",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ])
                                },
                              },
                            ],
                            null,
                            false,
                            3856506770
                          ),
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "default" }, slot: "default" },
                            [_c("div", [_vm._v("点击上传")])]
                          ),
                          _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v(
                                "最多上传三张照片。图片支持jpg、JPEG ，bmp，png。图片大小不超过5M"
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          attrs: { modal: false, visible: _vm.dialogVisibler },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogVisibler = $event
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              width: "100%",
                              src: _vm.dialogImageUrl,
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注:" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "选填，请填备注" },
                        model: {
                          value: _vm.addForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "remark", $$v)
                          },
                          expression: "addForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    "append-to-body": "",
                    visible: _vm.dialogCategoryVisible,
                    width: "800px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogCategoryVisible = $event
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" 添加经营品种"),
                    _c("span", { staticClass: "c-6 f-s-14" }, [
                      _vm._v("    最多选择5种"),
                    ]),
                  ]),
                  _c(
                    "el-row",
                    { staticClass: "m-b-20" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "m-b-20" },
                        [
                          _vm._v(" 已选:    "),
                          _vm._l(
                            _vm.dnpMerchantCategoryInfoList,
                            function (tag, index) {
                              return _c(
                                "el-tag",
                                {
                                  key: index,
                                  staticClass: "m-r-20",
                                  attrs: { closable: "" },
                                  on: {
                                    close: function ($event) {
                                      return _vm.handleCloser(index)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(tag.categoryName) + " ")]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "输入关键字进行过滤",
                            },
                            model: {
                              value: _vm.filterText,
                              callback: function ($$v) {
                                _vm.filterText = $$v
                              },
                              expression: "filterText",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-tree", {
                    ref: "treePriceParams",
                    attrs: {
                      data: _vm.treeData,
                      "node-key": "categoryId",
                      props: _vm.defaultProps,
                      "highlight-current": true,
                      "filter-node-method": _vm.filterNode,
                      "show-checkbox": "",
                      "check-strictly": "",
                      "check-on-click-node": true,
                    },
                    on: { "check-change": _vm.handlePriceClick },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "text-center",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogCategoryVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitTag },
                        },
                        [_vm._v("我 选 好 了")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "text-center",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.beforeClose()
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("addForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("DargList", {
        attrs: { dialogVisible: _vm.sortby },
        on: { dargList: _vm.dargList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }